import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, QueryResult } from "@apollo/client"
import { ConfigProvider } from "antd"
import _ from "lodash"
import React, { createContext, memo, useContext, useEffect, useState, type FC, type PropsWithChildren } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import introspection, { useGetCountriesQuery, useGetNotificationsQuery } from "../../graphql"
import { activatedSalonRoutes, guestRoutes, notActivatedSalonRoutes, otherLevelRoutes } from "../../pages"
import theme from "../../themes"
import { AuthProvider, useAuth } from "./Auth"
import "./index.less"
type ContextProps = {
  app: {
    isPageReady: boolean
    userId: number | undefined
    setSalonData: React.Dispatch<React.SetStateAction<SalonDataInterface | undefined>>
    salonData: SalonDataInterface | undefined
    setIsPageReady: React.Dispatch<React.SetStateAction<boolean>>
    userType: string | undefined
    userTypeId: string | undefined | null
    userName: string | undefined | null
    isNotificationOn: boolean
    setIsNotificationOn: React.Dispatch<React.SetStateAction<boolean>>
    preRegisterData: PreRegisterDataInterface | undefined
    setPreRegisterData: React.Dispatch<React.SetStateAction<PreRegisterDataInterface | undefined>>
    countries: { key: number; value: string; label: string; region: number }[] | undefined
  }
}
export interface SalonDataInterface {
  salon: GetSalonQuery["salon"]
  stylists: GetStylistsQuery["stylists"]
  submissions: GetSalonSubmissionsQuery["submissions"]
}
export interface PreRegisterDataInterface {
  email: string
  salon: QueryResult<GetSalonsQuery, GetSalonsQueryVariables>
  stylists: QueryResult<GetStylistsQuery, GetStylistsQueryVariables>
}
const Context = createContext<ContextProps>({
  app: {
    isPageReady: false,
    userId: undefined,
    setSalonData: () => {},
    salonData: undefined,
    setIsPageReady: () => {},
    userType: undefined,
    userTypeId: undefined,
    userName: undefined,
    isNotificationOn: false,
    setIsNotificationOn: () => {},
    preRegisterData: undefined,
    setPreRegisterData: () => {},
    countries: undefined,
  },
})

const useApp: () => ContextProps = () => useContext(Context)
const ContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const loginToken = urlParams.get("loginToken")
  const { loginByToken, logout, user } = useAuth()
  //here we get data of authorized user
  const [isPageReady, setIsPageReady] = useState<boolean>(false)
  //here we should detect when data from backend is ready
  const [userId, setUserId] = useState<number | undefined>()
  //here we authorize user by id and save the id
  const [userType, setUserType] = useState<string>("")
  //here we detect user id from collection (id of salon, id of DSC etc.)
  const [userTypeId, setUserTypeId] = useState<string>("")
  //here we detect user type ("salon", "DSC" etc.)
  const [userName, setUserName] = useState<string>()
  //here we detect username if it is not salon
  const [isNotificationOn, setIsNotificationOn] = useState<boolean>(true)
  //here we store state of notifications inside homepage
  const [salonData, setSalonData] = useState<SalonDataInterface>()
  //here we store salon data for ability to use it in homepage and in profile page
  const [preRegisterData, setPreRegisterData] = useState<PreRegisterDataInterface>()
  //here we add data about salon only if we need to redirect salon from homepage to signup page with filled data from previous year
  const { data: notificationsData } = useGetNotificationsQuery({
    variables: {
      filter: {
        user: {
          id: {
            eq: userId?.toString() as string,
          },
        },
      },
    },
  })
  const { data: countriesData } = useGetCountriesQuery()
  const countries =
    countriesData?.countries?.data && countriesData?.countries?.data?.length > 0
      ? countriesData?.countries?.data.map((country, i) => ({
          key: i,
          value: country.attributes?.Name?.toLowerCase()!,
          label: country.attributes?.Name!,
          region:
            country.attributes?.regionId?.data?.attributes?.name == "Europe - Middle East - Africa"
              ? 1
              : country.attributes?.regionId?.data?.attributes?.name == "North America"
              ? 2
              : 0,
        }))
      : undefined

  const app: ContextProps["app"] = {
    isPageReady: isPageReady,
    userId: userId,
    setSalonData,
    salonData,
    setIsPageReady,
    userType,
    userTypeId,
    userName,
    isNotificationOn,
    setIsNotificationOn,
    preRegisterData,
    setPreRegisterData,
    countries,
  }

  useEffect(() => {
    if (user) {
      setUserId(Number(user.id!))
      setUserType(_.get(user, "role.data.attributes.name", "").toLowerCase())
      setUserTypeId(
        _.get(user, "salon.data.id") ||
          _.get(user, "distributor.data.id") ||
          _.get(user, "sales_manager.data.id") ||
          _.get(user, "dsc.data.id") ||
          _.get(user, "region.data.id", "")
      )
      setUserName(_.get(user, "name", "")!)
    }
  }, [user])
  //here we place data about user in context
  const loginUser = () => {
    loginByToken(loginToken!)
      .then(response => {
        const userData = response as {
          id: string
          role: { data: { attributes: { name: string } } }
          salon: { data?: { id: string } }
          distributor: { data?: { id: string } }
          sales_manager: { data?: { id: string } }
          dsc: { data?: { id: string } }
          name: string
        }
        setUserId(Number(userData?.id!))
        setUserType(userData?.role?.data?.attributes?.name.toLowerCase() || "")
        setUserTypeId(
          userData?.salon?.data?.id ||
            userData?.distributor?.data?.id ||
            userData?.sales_manager?.data?.id ||
            userData?.dsc?.data?.id ||
            ""
        )
        setUserName(userData?.name || "")
      })
      //when user comes with login token for the first time, and we indicate him - we provide data about user to context
      .catch(error => {
        console.log("Network Error")
        const currentURL = window.location.origin
        if (error.message == "invalid.token") {
          logout().then(() => (window.location.href = currentURL + "/login"))
        }
      })
  }
  useEffect(() => {
    if (!userId && !userType && !userTypeId && !userName) {
      loginToken && !user && loginUser()
      //if user comes with loginToken, and we also have no user data in authProvider - we indicate auth process as finished
      loginToken && user && logout().then(() => loginUser())
      //if user comes with loginToken, and we also have user data in authProvider - we logout user and login again with his token
    }
  }, [loginToken])

  useEffect(() => {
    if (notificationsData) {
      setIsNotificationOn(notificationsData?.notifications?.data?.length == 0)
    }
    //when we get data about notifications we can provide them to context.
    //backend give us data only when notifications is disabled
  }, [notificationsData])

  return <Context.Provider value={{ app }}>{children}</Context.Provider>
}

const client = new ApolloClient({
  link: createHttpLink({
    uri: `${import.meta.env.WEBSITE_API_URL ?? "/graphql"}`,
    credentials: "same-origin",
  }),
  connectToDevTools: import.meta.env.DEV,
  queryDeduplication: true,
  assumeImmutableResults: true,
  cache: new InMemoryCache({
    resultCaching: import.meta.env.PROD,
    possibleTypes: introspection.possibleTypes,
  }),
})

const Router = () => {
  const { user } = useAuth()
  let routes
  if (!user) {
    routes = guestRoutes
  }
  if (user && !user?.salon?.data) {
    routes = otherLevelRoutes
  }
  if (user?.salon?.data?.attributes?.isActive == false) {
    routes = notActivatedSalonRoutes
  }
  if (user?.salon?.data?.attributes?.isActive) {
    routes = activatedSalonRoutes
  }
  const router = createBrowserRouter(routes!)
  return <RouterProvider router={router} />
}

const App: FC = memo(() => {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <ContextProvider>
          <ConfigProvider theme={theme}>
            <Router />
          </ConfigProvider>
        </ContextProvider>
      </AuthProvider>
    </ApolloProvider>
  )
})

export { useApp }

export default App
