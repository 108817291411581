import { QueryResult } from "@apollo/client"
import { Layout as BaseLayout } from "antd"
import React, { FC, Suspense, useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { Outlet } from "react-router-dom"
import { useGetSalonsLazyQuery, useGetStylistsLazyQuery } from "../../graphql"
import NoConnection from "../../pages/noConnection"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import DesctopNavbar from "../desctopNavbar"

const Layout: FC = () => {
  const [isOffline, setIsOffline] = useState(false)
  const [getSalonData] = useGetSalonsLazyQuery()
  const [getStylists] = useGetStylistsLazyQuery()
  const navigate = useNavigate()
  const { user } = useAuth()
  const {
    app: { setPreRegisterData, userType },
  } = useApp()

  const handleOfflineEvent = () => {
    setTimeout(() => {
      !navigator.onLine && setIsOffline(true)
    }, 3000)
  }

  const handleRecheckConnection = () => {
    navigator.onLine && setIsOffline(false)
  }

  const handleWhenUserNeedsToRefreshData = async () => {
    const isUserActive = await user?.salon?.data?.attributes?.isActive
    if (user && userType == "salon" && !isUserActive) {
      let salon
      getSalonData({
        variables: {
          filter: {
            isActive: { eq: false },
            owner: {
              email: { eq: user?.email },
            },
          },
        },
      }).then(response => {
        salon = response
        getStylists({
          variables: {
            pagination: {
              limit: 10000,
              start: 0,
            },
            filter: {
              salon: {
                id: { eq: salon?.data?.salons?.data[0]?.id },
              },
            },
          },
        }).then(response => {
          setPreRegisterData({
            email: user?.email!,
            salon: salon! as QueryResult<GetSalonsQuery, GetSalonsQueryVariables>,
            stylists: response as QueryResult<GetStylistsQuery, GetStylistsQueryVariables>,
          })
          navigate("/login")
        })
      })
    }
  }
  useEffect(() => {
    handleWhenUserNeedsToRefreshData().catch(() => console.log("Network Error"))
  }, [user])

  useEffect(() => {
    window.addEventListener("offline", handleOfflineEvent)
    return () => {
      window.removeEventListener("offline", handleOfflineEvent)
    }
  }, [])

  return (
    <BaseLayout>
      <DesctopNavbar />
      <Suspense>
        {!isOffline ? (
          <Outlet />
        ) : (
          <NoConnection user={user} userType={userType} handleRecheckConnection={handleRecheckConnection} />
        )}
      </Suspense>
    </BaseLayout>
  )
}

export { Layout }
