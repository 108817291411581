import React, { FC, lazy } from "react"
import { RouteObject } from "react-router-dom"
import DefaultLayout, { Loader } from "../components/layout"
import Page404 from "./Page404"
const Homepage = lazy<FC>(() => import("./homepage"))
const SignUpLogin = lazy<FC>(() => import("./signUpLogin"))
const Profile = lazy<FC>(() => import("./profile"))
const Leaderboard = lazy<FC>(() => import("./leaderboard"))
const videoLibrary = lazy<FC>(() => import("./videoLibrary"))
const HowItWorks = lazy<FC>(() => import("./howItWorks"))
const BrandUpdates = lazy<FC>(() => import("./brandUpdates"))
const SinglePost = lazy<FC>(() => import("./brandUpdates/page"))
const StartPage = lazy<FC>(() => import("./startPage"))
const Report = lazy<FC>(() => import("./report"))
const TermsAndConditions = lazy<FC>(() => import("./termsAndConditions"))
const ActivityLog = lazy<FC>(() => import("./activityLog"))
const DSCRanking = lazy<FC>(() => import("./dscRanking"))
export const guestRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: SignUpLogin,
        path: "/login",
        index: true,
      },
      {
        Component: StartPage,
        path: "/",
        index: true,
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
        index: true,
      },
      {
        Component: Page404,
        path: "*",
      },
      //paths that opened at the client request
      {
        Component: videoLibrary,
        path: "/library",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/*",
        index: true,
      },
      {
        Component: BrandUpdates,
        path: "/news",
        index: true,
      },
      {
        Component: SinglePost,
        path: "/news/*",
        index: true,
      },
    ],
  },
]

export const activatedSalonRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Homepage,
        path: "/homepage",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile/account",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile/notification",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile/team",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile/info",
        index: true,
      },
      {
        Component: Profile,
        path: "/profile/legal",
        index: true,
      },
      {
        Component: Leaderboard,
        path: "/leaderboard",
        index: true,
      },
      {
        Component: videoLibrary,
        path: "/library",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/about",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/categories",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/prizes",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/timeline",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/submission-and-validation",
        index: true,
      },
      {
        Component: BrandUpdates,
        path: "/news",
        index: true,
      },
      {
        Component: SinglePost,
        path: "/news/*",
        index: true,
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
        index: true,
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]

export const notActivatedSalonRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Homepage,
        path: "/homepage",
        index: true,
      },
      {
        Component: SignUpLogin,
        path: "/login",
        index: true,
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
        index: true,
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]

export const otherLevelRoutes: RouteObject[] = [
  {
    Component: DefaultLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Homepage,
        path: "/homepage",
        index: true,
      },
      {
        Component: Leaderboard,
        path: "/leaderboard",
        index: true,
      },
      {
        Component: videoLibrary,
        path: "/library",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/about",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/categories",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/prizes",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/timeline",
        index: true,
      },
      {
        Component: HowItWorks,
        path: "/how-it-works/submission-and-validation",
        index: true,
      },
      {
        Component: BrandUpdates,
        path: "/news",
        index: true,
      },
      {
        Component: SinglePost,
        path: "/news/*",
        index: true,
      },
      {
        Component: Report,
        path: "/report",
        index: true,
      },
      {
        Component: TermsAndConditions,
        path: "/terms",
        index: true,
      },
      {
        Component: ActivityLog,
        path: "/activity",
        index: true,
      },
      {
        Component: DSCRanking,
        path: "/dsc-ranking",
        index: true,
      },
      {
        Component: Page404,
        path: "*",
      },
    ],
  },
]
