import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ActivityLog",
      "AdminActivityLog",
      "AppEmailTemplate",
      "AppMoreSection",
      "AppNewsComment",
      "AppNewsCommentReaction",
      "AppNewsPost",
      "AppNewsReaction",
      "AppNotification",
      "AppNotificationType",
      "AppPrivacyPolicy",
      "AppResource",
      "AppSalesCompetitionType",
      "AppTermCondition",
      "BlogPost",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSizeAndImage",
      "ComponentUiTab",
      "ComponentUiText",
      "Country",
      "CoverImage",
      "Distributor",
      "Dsc",
      "Event",
      "Home",
      "HowItWorkSection",
      "I18NLocale",
      "Layout",
      "Leaderboard",
      "Notification",
      "PasswordlessToken",
      "Product",
      "ProductBenefit",
      "ProductType",
      "ReactIconsIconlibrary",
      "Region",
      "SalesManager",
      "Salon",
      "Stylist",
      "Submission",
      "TermCond",
      "UploadFile",
      "UploadFolder",
      "UserView",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VideoLibrary"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "GenericMorph": [
      "ActivityLog",
      "AdminActivityLog",
      "AppEmailTemplate",
      "AppMoreSection",
      "AppNewsComment",
      "AppNewsCommentReaction",
      "AppNewsPost",
      "AppNewsReaction",
      "AppNotification",
      "AppNotificationType",
      "AppPrivacyPolicy",
      "AppResource",
      "AppSalesCompetitionType",
      "AppTermCondition",
      "BlogPost",
      "ComponentDataEntry",
      "ComponentDataSet",
      "ComponentUiCard",
      "ComponentUiGrid",
      "ComponentUiHeadline",
      "ComponentUiLink",
      "ComponentUiParagraph",
      "ComponentUiSection",
      "ComponentUiSizeAndImage",
      "ComponentUiTab",
      "ComponentUiText",
      "Country",
      "CoverImage",
      "Distributor",
      "Dsc",
      "Event",
      "Home",
      "HowItWorkSection",
      "I18NLocale",
      "Layout",
      "Leaderboard",
      "Notification",
      "PasswordlessToken",
      "Product",
      "ProductBenefit",
      "ProductType",
      "ReactIconsIconlibrary",
      "Region",
      "SalesManager",
      "Salon",
      "Stylist",
      "Submission",
      "TermCond",
      "UploadFile",
      "UploadFolder",
      "UserView",
      "UsersPermissionsPermission",
      "UsersPermissionsRole",
      "UsersPermissionsUser",
      "VideoLibrary"
    ]
  }
};
      export default result;
    
export const EntryFragmentDoc = gql`
    fragment Entry on ComponentDataEntry {
  id
  key
  value
}
    `;
export const HeadlineFragmentDoc = gql`
    fragment Headline on ComponentUiHeadline {
  id
  title
  subtitle
}
    `;
export const MeFragmentDoc = gql`
    fragment Me on UsersPermissionsUser {
  id
  name
  email
  region {
    data {
      id
      attributes {
        name
      }
    }
  }
  active
  username
  salon {
    data {
      id
      attributes {
        isActive
        name
        avatar {
          data {
            id
            attributes {
              name
              url
            }
          }
        }
      }
    }
  }
  sales_manager {
    data {
      id
      attributes {
        name
      }
    }
  }
  dsc {
    data {
      id
      attributes {
        name
      }
    }
  }
  distributor {
    data {
      id
      attributes {
        name
      }
    }
  }
  role {
    data {
      attributes {
        name
      }
    }
  }
}
    `;
export const ParagraphFragmentDoc = gql`
    fragment Paragraph on ComponentUiParagraph {
  id
  value
}
    `;
export const LinkFragmentDoc = gql`
    fragment Link on ComponentUiLink {
  id
  title
  url
  target
}
    `;
export const FileFragmentDoc = gql`
    fragment File on UploadFileEntity {
  id
  attributes {
    previewUrl
    alternativeText
    url
  }
}
    `;
export const CardFragmentDoc = gql`
    fragment Card on ComponentUiCard {
  id
  title
  subtitle
  description
  media {
    data {
      ...File
    }
  }
}
    `;
export const SectionFragmentDoc = gql`
    fragment Section on ComponentUiSection {
  id
  button {
    ...Link
  }
  heading {
    ...Card
  }
}
    `;
export const TabFragmentDoc = gql`
    fragment Tab on ComponentUiTab {
  id
  name
  pane {
    ...Card
  }
}
    `;
export const CreateNotificationDocument = gql`
    mutation createNotification($data: NotificationInput!) {
  createNotification(data: $data) {
    data {
      attributes {
        user {
          data {
            id
            attributes {
              id
              name
            }
          }
        }
        NotificationOn
      }
    }
  }
}
    `;
export type CreateNotificationMutationFn = Apollo.MutationFunction<CreateNotificationMutation, CreateNotificationMutationVariables>;
export function useCreateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationMutation, CreateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationMutation, CreateNotificationMutationVariables>(CreateNotificationDocument, options);
      }
export type CreateNotificationMutationHookResult = ReturnType<typeof useCreateNotificationMutation>;
export type CreateNotificationMutationResult = Apollo.MutationResult<CreateNotificationMutation>;
export const CreateSalonDocument = gql`
    mutation createSalon($data: SalonInput!) {
  createSalon(data: $data) {
    data {
      id
      attributes {
        name
        isActive
        salonSize
        salonType
        totalStylists
        owner {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type CreateSalonMutationFn = Apollo.MutationFunction<CreateSalonMutation, CreateSalonMutationVariables>;
export function useCreateSalonMutation(baseOptions?: Apollo.MutationHookOptions<CreateSalonMutation, CreateSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSalonMutation, CreateSalonMutationVariables>(CreateSalonDocument, options);
      }
export type CreateSalonMutationHookResult = ReturnType<typeof useCreateSalonMutation>;
export type CreateSalonMutationResult = Apollo.MutationResult<CreateSalonMutation>;
export const CreateStylistDocument = gql`
    mutation createStylist($data: StylistInput!) {
  createStylist(data: $data) {
    data {
      attributes {
        name
        email
        isActive
        salon {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateStylistMutationFn = Apollo.MutationFunction<CreateStylistMutation, CreateStylistMutationVariables>;
export function useCreateStylistMutation(baseOptions?: Apollo.MutationHookOptions<CreateStylistMutation, CreateStylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStylistMutation, CreateStylistMutationVariables>(CreateStylistDocument, options);
      }
export type CreateStylistMutationHookResult = ReturnType<typeof useCreateStylistMutation>;
export type CreateStylistMutationResult = Apollo.MutationResult<CreateStylistMutation>;
export const CreateSubmissionDocument = gql`
    mutation createSubmission($data: SubmissionInput!) {
  createSubmission(data: $data) {
    data {
      id
      attributes {
        weekNumber
        fullSize
        miniSize
        points
        stylist {
          data {
            id
          }
        }
        salon {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type CreateSubmissionMutationFn = Apollo.MutationFunction<CreateSubmissionMutation, CreateSubmissionMutationVariables>;
export function useCreateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubmissionMutation, CreateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubmissionMutation, CreateSubmissionMutationVariables>(CreateSubmissionDocument, options);
      }
export type CreateSubmissionMutationHookResult = ReturnType<typeof useCreateSubmissionMutation>;
export type CreateSubmissionMutationResult = Apollo.MutationResult<CreateSubmissionMutation>;
export const CreateUserDocument = gql`
    mutation createUser($data: UsersPermissionsUserInput!) {
  createUsersPermissionsUser(data: $data) {
    data {
      id
      attributes {
        confirmed
        email
        username
        role {
          data {
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export const CreateRegionDocument = gql`
    mutation createRegion($data: RegionInput!) {
  createRegion(data: $data) {
    data {
      id
      attributes {
        name
        email
      }
    }
  }
}
    `;
export type CreateRegionMutationFn = Apollo.MutationFunction<CreateRegionMutation, CreateRegionMutationVariables>;
export function useCreateRegionMutation(baseOptions?: Apollo.MutationHookOptions<CreateRegionMutation, CreateRegionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRegionMutation, CreateRegionMutationVariables>(CreateRegionDocument, options);
      }
export type CreateRegionMutationHookResult = ReturnType<typeof useCreateRegionMutation>;
export type CreateRegionMutationResult = Apollo.MutationResult<CreateRegionMutation>;
export const DeleteFileDocument = gql`
    mutation deleteFile($id: ID!) {
  deleteUploadFile(id: $id) {
    data {
      id
    }
  }
}
    `;
export type DeleteFileMutationFn = Apollo.MutationFunction<DeleteFileMutation, DeleteFileMutationVariables>;
export function useDeleteFileMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFileMutation, DeleteFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFileMutation, DeleteFileMutationVariables>(DeleteFileDocument, options);
      }
export type DeleteFileMutationHookResult = ReturnType<typeof useDeleteFileMutation>;
export type DeleteFileMutationResult = Apollo.MutationResult<DeleteFileMutation>;
export const DeleteNotificationDocument = gql`
    mutation deleteNotification($deleteNotificaitonId: ID!) {
  deleteNotification(id: $deleteNotificaitonId) {
    data {
      id
    }
  }
}
    `;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<DeleteNotificationMutation, DeleteNotificationMutationVariables>;
export function useDeleteNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteNotificationMutation, DeleteNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(DeleteNotificationDocument, options);
      }
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export const DeleteStylistDocument = gql`
    mutation deleteStylist($deleteStylistId: ID!) {
  deleteStylist(id: $deleteStylistId) {
    data {
      attributes {
        email
        name
        isActive
      }
    }
  }
}
    `;
export type DeleteStylistMutationFn = Apollo.MutationFunction<DeleteStylistMutation, DeleteStylistMutationVariables>;
export function useDeleteStylistMutation(baseOptions?: Apollo.MutationHookOptions<DeleteStylistMutation, DeleteStylistMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteStylistMutation, DeleteStylistMutationVariables>(DeleteStylistDocument, options);
      }
export type DeleteStylistMutationHookResult = ReturnType<typeof useDeleteStylistMutation>;
export type DeleteStylistMutationResult = Apollo.MutationResult<DeleteStylistMutation>;
export const HandleSubmissionDocument = gql`
    mutation handleSubmission($input: HandleSubmissionInput!) {
  handleSubmission(input: $input) {
    success
  }
}
    `;
export type HandleSubmissionMutationFn = Apollo.MutationFunction<HandleSubmissionMutation, HandleSubmissionMutationVariables>;
export function useHandleSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<HandleSubmissionMutation, HandleSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleSubmissionMutation, HandleSubmissionMutationVariables>(HandleSubmissionDocument, options);
      }
export type HandleSubmissionMutationHookResult = ReturnType<typeof useHandleSubmissionMutation>;
export type HandleSubmissionMutationResult = Apollo.MutationResult<HandleSubmissionMutation>;
export const HandleValidationDocument = gql`
    mutation handleValidation($input: HandleValidationInput!) {
  handleValidation(input: $input) {
    success
  }
}
    `;
export type HandleValidationMutationFn = Apollo.MutationFunction<HandleValidationMutation, HandleValidationMutationVariables>;
export function useHandleValidationMutation(baseOptions?: Apollo.MutationHookOptions<HandleValidationMutation, HandleValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleValidationMutation, HandleValidationMutationVariables>(HandleValidationDocument, options);
      }
export type HandleValidationMutationHookResult = ReturnType<typeof useHandleValidationMutation>;
export type HandleValidationMutationResult = Apollo.MutationResult<HandleValidationMutation>;
export const LoginByPasswordlessDocument = gql`
    mutation loginByPasswordless($input: LoginTokenInput!) {
  loginByPasswordless(input: $input) {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export type LoginByPasswordlessMutationFn = Apollo.MutationFunction<LoginByPasswordlessMutation, LoginByPasswordlessMutationVariables>;
export function useLoginByPasswordlessMutation(baseOptions?: Apollo.MutationHookOptions<LoginByPasswordlessMutation, LoginByPasswordlessMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByPasswordlessMutation, LoginByPasswordlessMutationVariables>(LoginByPasswordlessDocument, options);
      }
export type LoginByPasswordlessMutationHookResult = ReturnType<typeof useLoginByPasswordlessMutation>;
export type LoginByPasswordlessMutationResult = Apollo.MutationResult<LoginByPasswordlessMutation>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export const NewSalonDocument = gql`
    mutation newSalon($input: NewSalonInput!) {
  newSalon(input: $input)
}
    `;
export type NewSalonMutationFn = Apollo.MutationFunction<NewSalonMutation, NewSalonMutationVariables>;
export function useNewSalonMutation(baseOptions?: Apollo.MutationHookOptions<NewSalonMutation, NewSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<NewSalonMutation, NewSalonMutationVariables>(NewSalonDocument, options);
      }
export type NewSalonMutationHookResult = ReturnType<typeof useNewSalonMutation>;
export type NewSalonMutationResult = Apollo.MutationResult<NewSalonMutation>;
export const SendAuthLinkDocument = gql`
    mutation sendAuthLink($input: CreateLinkInput!) {
  sendAuthLink(input: $input) {
    isSent
    isActive
  }
}
    `;
export type SendAuthLinkMutationFn = Apollo.MutationFunction<SendAuthLinkMutation, SendAuthLinkMutationVariables>;
export function useSendAuthLinkMutation(baseOptions?: Apollo.MutationHookOptions<SendAuthLinkMutation, SendAuthLinkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendAuthLinkMutation, SendAuthLinkMutationVariables>(SendAuthLinkDocument, options);
      }
export type SendAuthLinkMutationHookResult = ReturnType<typeof useSendAuthLinkMutation>;
export type SendAuthLinkMutationResult = Apollo.MutationResult<SendAuthLinkMutation>;
export const UpdateSalonDocument = gql`
    mutation updateSalon($id: ID!, $data: SalonInput!) {
  updateSalon(id: $id, data: $data) {
    data {
      id
      attributes {
        salonAge
        name
        isActive
        salonSize
        salonType
        totalStylists
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              name
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export type UpdateSalonMutationFn = Apollo.MutationFunction<UpdateSalonMutation, UpdateSalonMutationVariables>;
export function useUpdateSalonMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSalonMutation, UpdateSalonMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSalonMutation, UpdateSalonMutationVariables>(UpdateSalonDocument, options);
      }
export type UpdateSalonMutationHookResult = ReturnType<typeof useUpdateSalonMutation>;
export type UpdateSalonMutationResult = Apollo.MutationResult<UpdateSalonMutation>;
export const UpdateSubmissionDocument = gql`
    mutation updateSubmission($id: ID!, $data: SubmissionInput!) {
  updateSubmission(id: $id, data: $data) {
    data {
      id
      attributes {
        validatedAt
        validatedBy {
          data {
            attributes {
              username
            }
          }
        }
        weekNumber
        fullSize
        miniSize
        points
        stylist {
          data {
            id
          }
        }
        salon {
          data {
            id
          }
        }
      }
    }
  }
}
    `;
export type UpdateSubmissionMutationFn = Apollo.MutationFunction<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>;
export function useUpdateSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubmissionMutation, UpdateSubmissionMutationVariables>(UpdateSubmissionDocument, options);
      }
export type UpdateSubmissionMutationHookResult = ReturnType<typeof useUpdateSubmissionMutation>;
export type UpdateSubmissionMutationResult = Apollo.MutationResult<UpdateSubmissionMutation>;
export const UpdateUserDocument = gql`
    mutation updateUser($id: ID!, $data: UsersPermissionsUserInput!) {
  updateUsersPermissionsUser(id: $id, data: $data) {
    data {
      id
      attributes {
        email
        username
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export const GetActiveSalonsAmountDocument = gql`
    query getActiveSalonsAmount($input: ActiveSalonsInput!) {
  activeSalons(input: $input) {
    amount
  }
}
    `;
export function useGetActiveSalonsAmountQuery(baseOptions: Apollo.QueryHookOptions<GetActiveSalonsAmountQuery, GetActiveSalonsAmountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActiveSalonsAmountQuery, GetActiveSalonsAmountQueryVariables>(GetActiveSalonsAmountDocument, options);
      }
export function useGetActiveSalonsAmountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActiveSalonsAmountQuery, GetActiveSalonsAmountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActiveSalonsAmountQuery, GetActiveSalonsAmountQueryVariables>(GetActiveSalonsAmountDocument, options);
        }
export type GetActiveSalonsAmountQueryHookResult = ReturnType<typeof useGetActiveSalonsAmountQuery>;
export type GetActiveSalonsAmountLazyQueryHookResult = ReturnType<typeof useGetActiveSalonsAmountLazyQuery>;
export type GetActiveSalonsAmountQueryResult = Apollo.QueryResult<GetActiveSalonsAmountQuery, GetActiveSalonsAmountQueryVariables>;
export const GetActivityDocument = gql`
    query getActivity($filter: ActivityLogFiltersInput, $sort: [String], $pagination: PaginationArg) {
  activityLogs(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      attributes {
        createdAt
        quantity
        type
        validator {
          data {
            attributes {
              name
              region {
                data {
                  attributes {
                    name
                  }
                }
              }
              distributor {
                data {
                  attributes {
                    name
                  }
                }
              }
              sales_manager {
                data {
                  attributes {
                    name
                  }
                }
              }
              dsc {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        submission {
          data {
            id
            attributes {
              stylist {
                data {
                  attributes {
                    name
                  }
                }
              }
              points
              fullSize
              miniSize
              salon {
                data {
                  attributes {
                    name
                    distributor {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    dsc {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    sales_manager {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    owner {
                      data {
                        id
                        attributes {
                          email
                          username
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetActivityQuery(baseOptions?: Apollo.QueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
      }
export function useGetActivityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetActivityQuery, GetActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetActivityQuery, GetActivityQueryVariables>(GetActivityDocument, options);
        }
export type GetActivityQueryHookResult = ReturnType<typeof useGetActivityQuery>;
export type GetActivityLazyQueryHookResult = ReturnType<typeof useGetActivityLazyQuery>;
export type GetActivityQueryResult = Apollo.QueryResult<GetActivityQuery, GetActivityQueryVariables>;
export const GetBlogPostsDocument = gql`
    query getBlogPosts($filter: BlogPostFiltersInput, $pagination: PaginationArg) {
  blogPosts(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        title
        imageURL
        postText
        tag
        postImage {
          data {
            attributes {
              formats
              url
              formats
              name
              alternativeText
              blurhash
              caption
              height
              mime
              ext
              previewUrl
              width
              hash
              provider
            }
          }
        }
      }
    }
    meta {
      pagination {
        pageSize
        pageCount
        total
        page
      }
    }
  }
}
    `;
export function useGetBlogPostsQuery(baseOptions?: Apollo.QueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
      }
export function useGetBlogPostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostsQuery, GetBlogPostsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostsQuery, GetBlogPostsQueryVariables>(GetBlogPostsDocument, options);
        }
export type GetBlogPostsQueryHookResult = ReturnType<typeof useGetBlogPostsQuery>;
export type GetBlogPostsLazyQueryHookResult = ReturnType<typeof useGetBlogPostsLazyQuery>;
export type GetBlogPostsQueryResult = Apollo.QueryResult<GetBlogPostsQuery, GetBlogPostsQueryVariables>;
export const GetBlogPostDocument = gql`
    query getBlogPost($id: ID!) {
  blogPost(id: $id) {
    data {
      attributes {
        title
        videoLink
        tag
        postText
        imageURL
        postImage {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetBlogPostQuery(baseOptions: Apollo.QueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
      }
export function useGetBlogPostLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlogPostQuery, GetBlogPostQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBlogPostQuery, GetBlogPostQueryVariables>(GetBlogPostDocument, options);
        }
export type GetBlogPostQueryHookResult = ReturnType<typeof useGetBlogPostQuery>;
export type GetBlogPostLazyQueryHookResult = ReturnType<typeof useGetBlogPostLazyQuery>;
export type GetBlogPostQueryResult = Apollo.QueryResult<GetBlogPostQuery, GetBlogPostQueryVariables>;
export const GetChartDocument = gql`
    query getChart($input: ChartInput!) {
  chart(input: $input) {
    minsize
    maxsize
    all
  }
}
    `;
export function useGetChartQuery(baseOptions: Apollo.QueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, options);
      }
export function useGetChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChartQuery, GetChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChartQuery, GetChartQueryVariables>(GetChartDocument, options);
        }
export type GetChartQueryHookResult = ReturnType<typeof useGetChartQuery>;
export type GetChartLazyQueryHookResult = ReturnType<typeof useGetChartLazyQuery>;
export type GetChartQueryResult = Apollo.QueryResult<GetChartQuery, GetChartQueryVariables>;
export const GetCountriesDocument = gql`
    query getCountries {
  countries {
    data {
      attributes {
        Name
        regionId {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
      }
export function useGetCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCountriesQuery, GetCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCountriesQuery, GetCountriesQueryVariables>(GetCountriesDocument, options);
        }
export type GetCountriesQueryHookResult = ReturnType<typeof useGetCountriesQuery>;
export type GetCountriesLazyQueryHookResult = ReturnType<typeof useGetCountriesLazyQuery>;
export type GetCountriesQueryResult = Apollo.QueryResult<GetCountriesQuery, GetCountriesQueryVariables>;
export const GetCoverImagesDocument = gql`
    query getCoverImages($filter: CoverImageFiltersInput) {
  coverImages(filters: $filter) {
    data {
      attributes {
        image {
          data {
            attributes {
              url
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetCoverImagesQuery(baseOptions?: Apollo.QueryHookOptions<GetCoverImagesQuery, GetCoverImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCoverImagesQuery, GetCoverImagesQueryVariables>(GetCoverImagesDocument, options);
      }
export function useGetCoverImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCoverImagesQuery, GetCoverImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCoverImagesQuery, GetCoverImagesQueryVariables>(GetCoverImagesDocument, options);
        }
export type GetCoverImagesQueryHookResult = ReturnType<typeof useGetCoverImagesQuery>;
export type GetCoverImagesLazyQueryHookResult = ReturnType<typeof useGetCoverImagesLazyQuery>;
export type GetCoverImagesQueryResult = Apollo.QueryResult<GetCoverImagesQuery, GetCoverImagesQueryVariables>;
export const GetCurrentWeekDocument = gql`
    query getCurrentWeek {
  home {
    data {
      attributes {
        currentWeek
      }
    }
  }
}
    `;
export function useGetCurrentWeekQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentWeekQuery, GetCurrentWeekQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentWeekQuery, GetCurrentWeekQueryVariables>(GetCurrentWeekDocument, options);
      }
export function useGetCurrentWeekLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentWeekQuery, GetCurrentWeekQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentWeekQuery, GetCurrentWeekQueryVariables>(GetCurrentWeekDocument, options);
        }
export type GetCurrentWeekQueryHookResult = ReturnType<typeof useGetCurrentWeekQuery>;
export type GetCurrentWeekLazyQueryHookResult = ReturnType<typeof useGetCurrentWeekLazyQuery>;
export type GetCurrentWeekQueryResult = Apollo.QueryResult<GetCurrentWeekQuery, GetCurrentWeekQueryVariables>;
export const GetDsCsDocument = gql`
    query getDSCs($filter: DscFiltersInput, $pagination: PaginationArg) {
  dscs(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        name
        refID
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetDsCsQuery(baseOptions?: Apollo.QueryHookOptions<GetDsCsQuery, GetDsCsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDsCsQuery, GetDsCsQueryVariables>(GetDsCsDocument, options);
      }
export function useGetDsCsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDsCsQuery, GetDsCsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDsCsQuery, GetDsCsQueryVariables>(GetDsCsDocument, options);
        }
export type GetDsCsQueryHookResult = ReturnType<typeof useGetDsCsQuery>;
export type GetDsCsLazyQueryHookResult = ReturnType<typeof useGetDsCsLazyQuery>;
export type GetDsCsQueryResult = Apollo.QueryResult<GetDsCsQuery, GetDsCsQueryVariables>;
export const GetDashboardSalonsListDocument = gql`
    query getDashboardSalonsList($input: DashboardSalonsListInput!) {
  dashboardSalonsList(input: $input) {
    salon
    salonId
    maxsize
    minsize
    points
    stylists {
      stylistId
      maxsize
      minsize
      points
      stylist
      submissionId
      updated
      validatedAt
      validationTime
      validatedBy
    }
    updated
  }
}
    `;
export function useGetDashboardSalonsListQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardSalonsListQuery, GetDashboardSalonsListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardSalonsListQuery, GetDashboardSalonsListQueryVariables>(GetDashboardSalonsListDocument, options);
      }
export function useGetDashboardSalonsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardSalonsListQuery, GetDashboardSalonsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardSalonsListQuery, GetDashboardSalonsListQueryVariables>(GetDashboardSalonsListDocument, options);
        }
export type GetDashboardSalonsListQueryHookResult = ReturnType<typeof useGetDashboardSalonsListQuery>;
export type GetDashboardSalonsListLazyQueryHookResult = ReturnType<typeof useGetDashboardSalonsListLazyQuery>;
export type GetDashboardSalonsListQueryResult = Apollo.QueryResult<GetDashboardSalonsListQuery, GetDashboardSalonsListQueryVariables>;
export const GetDistributorsDocument = gql`
    query getDistributors($filter: DistributorFiltersInput, $pagination: PaginationArg) {
  distributors(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        name
        refID
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetDistributorsQuery(baseOptions?: Apollo.QueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
      }
export function useGetDistributorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistributorsQuery, GetDistributorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistributorsQuery, GetDistributorsQueryVariables>(GetDistributorsDocument, options);
        }
export type GetDistributorsQueryHookResult = ReturnType<typeof useGetDistributorsQuery>;
export type GetDistributorsLazyQueryHookResult = ReturnType<typeof useGetDistributorsLazyQuery>;
export type GetDistributorsQueryResult = Apollo.QueryResult<GetDistributorsQuery, GetDistributorsQueryVariables>;
export const GetDscLeaderboardDocument = gql`
    query getDscLeaderboard($input: DscLeaderboardInput!) {
  dscLeaderboard(input: $input) {
    dsc
    region
    totalSalons
    totalSubmissions
    totalEntries
    verifiedPoints
    totalPoints
  }
}
    `;
export function useGetDscLeaderboardQuery(baseOptions: Apollo.QueryHookOptions<GetDscLeaderboardQuery, GetDscLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDscLeaderboardQuery, GetDscLeaderboardQueryVariables>(GetDscLeaderboardDocument, options);
      }
export function useGetDscLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDscLeaderboardQuery, GetDscLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDscLeaderboardQuery, GetDscLeaderboardQueryVariables>(GetDscLeaderboardDocument, options);
        }
export type GetDscLeaderboardQueryHookResult = ReturnType<typeof useGetDscLeaderboardQuery>;
export type GetDscLeaderboardLazyQueryHookResult = ReturnType<typeof useGetDscLeaderboardLazyQuery>;
export type GetDscLeaderboardQueryResult = Apollo.QueryResult<GetDscLeaderboardQuery, GetDscLeaderboardQueryVariables>;
export const GetEntriesDocument = gql`
    query getEntries($input: EntriesInput!) {
  entries(input: $input) {
    allEntries
    weeksEntries
  }
}
    `;
export function useGetEntriesQuery(baseOptions: Apollo.QueryHookOptions<GetEntriesQuery, GetEntriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEntriesQuery, GetEntriesQueryVariables>(GetEntriesDocument, options);
      }
export function useGetEntriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEntriesQuery, GetEntriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEntriesQuery, GetEntriesQueryVariables>(GetEntriesDocument, options);
        }
export type GetEntriesQueryHookResult = ReturnType<typeof useGetEntriesQuery>;
export type GetEntriesLazyQueryHookResult = ReturnType<typeof useGetEntriesLazyQuery>;
export type GetEntriesQueryResult = Apollo.QueryResult<GetEntriesQuery, GetEntriesQueryVariables>;
export const GetGlobalRankingPlaceDocument = gql`
    query getGlobalRankingPlace($input: GlobalRankingPlaceInput!) {
  globalRankingPlace(input: $input) {
    place
  }
}
    `;
export function useGetGlobalRankingPlaceQuery(baseOptions: Apollo.QueryHookOptions<GetGlobalRankingPlaceQuery, GetGlobalRankingPlaceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGlobalRankingPlaceQuery, GetGlobalRankingPlaceQueryVariables>(GetGlobalRankingPlaceDocument, options);
      }
export function useGetGlobalRankingPlaceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGlobalRankingPlaceQuery, GetGlobalRankingPlaceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGlobalRankingPlaceQuery, GetGlobalRankingPlaceQueryVariables>(GetGlobalRankingPlaceDocument, options);
        }
export type GetGlobalRankingPlaceQueryHookResult = ReturnType<typeof useGetGlobalRankingPlaceQuery>;
export type GetGlobalRankingPlaceLazyQueryHookResult = ReturnType<typeof useGetGlobalRankingPlaceLazyQuery>;
export type GetGlobalRankingPlaceQueryResult = Apollo.QueryResult<GetGlobalRankingPlaceQuery, GetGlobalRankingPlaceQueryVariables>;
export const GetHowItWorksSectionDocument = gql`
    query getHowItWorksSection($filter: HowItWorkSectionFiltersInput) {
  howItWorkSections(filters: $filter) {
    data {
      attributes {
        section
        text
        submissionDeadline
      }
    }
  }
}
    `;
export function useGetHowItWorksSectionQuery(baseOptions?: Apollo.QueryHookOptions<GetHowItWorksSectionQuery, GetHowItWorksSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetHowItWorksSectionQuery, GetHowItWorksSectionQueryVariables>(GetHowItWorksSectionDocument, options);
      }
export function useGetHowItWorksSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetHowItWorksSectionQuery, GetHowItWorksSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetHowItWorksSectionQuery, GetHowItWorksSectionQueryVariables>(GetHowItWorksSectionDocument, options);
        }
export type GetHowItWorksSectionQueryHookResult = ReturnType<typeof useGetHowItWorksSectionQuery>;
export type GetHowItWorksSectionLazyQueryHookResult = ReturnType<typeof useGetHowItWorksSectionLazyQuery>;
export type GetHowItWorksSectionQueryResult = Apollo.QueryResult<GetHowItWorksSectionQuery, GetHowItWorksSectionQueryVariables>;
export const GetLeaderboardDocument = gql`
    query getLeaderboard($filter: LeaderboardFiltersInput, $sort: [String], $pagination: PaginationArg) {
  leaderboards(filters: $filter, sort: $sort, pagination: $pagination) {
    data {
      attributes {
        week
        points
        averagePoints
        stylistsAmount
        fullSize
        miniSize
        verified
        salon {
          data {
            id
            attributes {
              name
              country
              isActive
              distributor {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              dsc {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              sales_manager {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
              region {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        stylist {
          data {
            id
            attributes {
              name
              salon {
                data {
                  id
                  attributes {
                    name
                    country
                    distributor {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                    region {
                      data {
                        id
                        attributes {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetLeaderboardQuery(baseOptions?: Apollo.QueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
      }
export function useGetLeaderboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaderboardQuery, GetLeaderboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaderboardQuery, GetLeaderboardQueryVariables>(GetLeaderboardDocument, options);
        }
export type GetLeaderboardQueryHookResult = ReturnType<typeof useGetLeaderboardQuery>;
export type GetLeaderboardLazyQueryHookResult = ReturnType<typeof useGetLeaderboardLazyQuery>;
export type GetLeaderboardQueryResult = Apollo.QueryResult<GetLeaderboardQuery, GetLeaderboardQueryVariables>;
export const GetLeaderboardTotalDocument = gql`
    query getLeaderboardTotal($input: LeaderboardTotalInput!) {
  leaderboardTotal(input: $input) {
    stylist
    salon
    distributor
    country
    miniSize
    fullSize
    totalPoints
    stylistsAmount
    averagePoints
  }
}
    `;
export function useGetLeaderboardTotalQuery(baseOptions: Apollo.QueryHookOptions<GetLeaderboardTotalQuery, GetLeaderboardTotalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLeaderboardTotalQuery, GetLeaderboardTotalQueryVariables>(GetLeaderboardTotalDocument, options);
      }
export function useGetLeaderboardTotalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeaderboardTotalQuery, GetLeaderboardTotalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLeaderboardTotalQuery, GetLeaderboardTotalQueryVariables>(GetLeaderboardTotalDocument, options);
        }
export type GetLeaderboardTotalQueryHookResult = ReturnType<typeof useGetLeaderboardTotalQuery>;
export type GetLeaderboardTotalLazyQueryHookResult = ReturnType<typeof useGetLeaderboardTotalLazyQuery>;
export type GetLeaderboardTotalQueryResult = Apollo.QueryResult<GetLeaderboardTotalQuery, GetLeaderboardTotalQueryVariables>;
export const GetNotificationsDocument = gql`
    query getNotifications($filter: NotificationFiltersInput) {
  notifications(filters: $filter) {
    data {
      id
      attributes {
        NotificationOn
      }
    }
  }
}
    `;
export function useGetNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
      }
export function useGetNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNotificationsQuery, GetNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(GetNotificationsDocument, options);
        }
export type GetNotificationsQueryHookResult = ReturnType<typeof useGetNotificationsQuery>;
export type GetNotificationsLazyQueryHookResult = ReturnType<typeof useGetNotificationsLazyQuery>;
export type GetNotificationsQueryResult = Apollo.QueryResult<GetNotificationsQuery, GetNotificationsQueryVariables>;
export const GetReportDocument = gql`
    query getReport($input: ReportInput!) {
  report(input: $input) {
    salon
    country
    state
    total
    verified
    unverified
    region {
      name
      id
    }
    distributor {
      name
      id
    }
    sales_manager {
      name
      id
    }
    dsc {
      name
      id
    }
  }
}
    `;
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetRolesDocument = gql`
    query getRoles {
  usersPermissionsRoles {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetRolesQuery(baseOptions?: Apollo.QueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
      }
export function useGetRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRolesQuery, GetRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRolesQuery, GetRolesQueryVariables>(GetRolesDocument, options);
        }
export type GetRolesQueryHookResult = ReturnType<typeof useGetRolesQuery>;
export type GetRolesLazyQueryHookResult = ReturnType<typeof useGetRolesLazyQuery>;
export type GetRolesQueryResult = Apollo.QueryResult<GetRolesQuery, GetRolesQueryVariables>;
export const GetSalesManagersDocument = gql`
    query getSalesManagers($filter: SalesManagerFiltersInput, $pagination: PaginationArg) {
  salesManagers(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        distributor {
          data {
            id
            attributes {
              name
              refID
              region {
                data {
                  id
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
        name
        refID
        region {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalesManagersQuery(baseOptions?: Apollo.QueryHookOptions<GetSalesManagersQuery, GetSalesManagersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalesManagersQuery, GetSalesManagersQueryVariables>(GetSalesManagersDocument, options);
      }
export function useGetSalesManagersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalesManagersQuery, GetSalesManagersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalesManagersQuery, GetSalesManagersQueryVariables>(GetSalesManagersDocument, options);
        }
export type GetSalesManagersQueryHookResult = ReturnType<typeof useGetSalesManagersQuery>;
export type GetSalesManagersLazyQueryHookResult = ReturnType<typeof useGetSalesManagersLazyQuery>;
export type GetSalesManagersQueryResult = Apollo.QueryResult<GetSalesManagersQuery, GetSalesManagersQueryVariables>;
export const GetSalonDocument = gql`
    query getSalon($id: ID!) {
  salon(id: $id) {
    data {
      id
      attributes {
        name
        isActive
        salonSize
        salonType
        salonAge
        avatar {
          data {
            id
            attributes {
              name
              url
            }
          }
        }
        totalStylists
        country
        city
        state
        region {
          data {
            id
            attributes {
              name
              refID
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        owner {
          data {
            id
            attributes {
              email
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonQuery(baseOptions: Apollo.QueryHookOptions<GetSalonQuery, GetSalonQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonQuery, GetSalonQueryVariables>(GetSalonDocument, options);
      }
export function useGetSalonLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonQuery, GetSalonQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonQuery, GetSalonQueryVariables>(GetSalonDocument, options);
        }
export type GetSalonQueryHookResult = ReturnType<typeof useGetSalonQuery>;
export type GetSalonLazyQueryHookResult = ReturnType<typeof useGetSalonLazyQuery>;
export type GetSalonQueryResult = Apollo.QueryResult<GetSalonQuery, GetSalonQueryVariables>;
export const GetSalonNamesDocument = gql`
    query getSalonNames($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useGetSalonNamesQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonNamesQuery, GetSalonNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonNamesQuery, GetSalonNamesQueryVariables>(GetSalonNamesDocument, options);
      }
export function useGetSalonNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonNamesQuery, GetSalonNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonNamesQuery, GetSalonNamesQueryVariables>(GetSalonNamesDocument, options);
        }
export type GetSalonNamesQueryHookResult = ReturnType<typeof useGetSalonNamesQuery>;
export type GetSalonNamesLazyQueryHookResult = ReturnType<typeof useGetSalonNamesLazyQuery>;
export type GetSalonNamesQueryResult = Apollo.QueryResult<GetSalonNamesQuery, GetSalonNamesQueryVariables>;
export const GetSalonSubmissionsDocument = gql`
    query getSalonSubmissions($filter: SubmissionFiltersInput!, $pagination: PaginationArg, $sort: [String]) {
  submissions(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        entryDate
        validatedAt
        validatedBy {
          data {
            attributes {
              name
              username
            }
          }
        }
        createdAt
        updatedAt
        weekNumber
        fullSize
        miniSize
        weekNumber
        points
        salon {
          data {
            id
            attributes {
              name
            }
          }
        }
        stylist {
          data {
            id
            attributes {
              name
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonSubmissionsQuery(baseOptions: Apollo.QueryHookOptions<GetSalonSubmissionsQuery, GetSalonSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonSubmissionsQuery, GetSalonSubmissionsQueryVariables>(GetSalonSubmissionsDocument, options);
      }
export function useGetSalonSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonSubmissionsQuery, GetSalonSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonSubmissionsQuery, GetSalonSubmissionsQueryVariables>(GetSalonSubmissionsDocument, options);
        }
export type GetSalonSubmissionsQueryHookResult = ReturnType<typeof useGetSalonSubmissionsQuery>;
export type GetSalonSubmissionsLazyQueryHookResult = ReturnType<typeof useGetSalonSubmissionsLazyQuery>;
export type GetSalonSubmissionsQueryResult = Apollo.QueryResult<GetSalonSubmissionsQuery, GetSalonSubmissionsQueryVariables>;
export const GetSalonsDocument = gql`
    query getSalons($filter: SalonFiltersInput, $pagination: PaginationArg, $sort: [String]) {
  salons(filters: $filter, pagination: $pagination, sort: $sort) {
    data {
      id
      attributes {
        name
        isActive
        salonSize
        salonType
        salonAge
        totalStylists
        country
        city
        state
        region {
          data {
            id
            attributes {
              name
              refID
            }
          }
        }
        distributor {
          data {
            id
            attributes {
              name
            }
          }
        }
        dsc {
          data {
            id
            attributes {
              name
            }
          }
        }
        sales_manager {
          data {
            id
            attributes {
              name
            }
          }
        }
        owner {
          data {
            id
            attributes {
              email
              username
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetSalonsQuery(baseOptions?: Apollo.QueryHookOptions<GetSalonsQuery, GetSalonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSalonsQuery, GetSalonsQueryVariables>(GetSalonsDocument, options);
      }
export function useGetSalonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSalonsQuery, GetSalonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSalonsQuery, GetSalonsQueryVariables>(GetSalonsDocument, options);
        }
export type GetSalonsQueryHookResult = ReturnType<typeof useGetSalonsQuery>;
export type GetSalonsLazyQueryHookResult = ReturnType<typeof useGetSalonsLazyQuery>;
export type GetSalonsQueryResult = Apollo.QueryResult<GetSalonsQuery, GetSalonsQueryVariables>;
export const GetStylistsDocument = gql`
    query getStylists($filter: StylistFiltersInput!, $pagination: PaginationArg) {
  stylists(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        name
        email
        isActive
        salon {
          data {
            id
            attributes {
              country
              isActive
              region {
                data {
                  attributes {
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export function useGetStylistsQuery(baseOptions: Apollo.QueryHookOptions<GetStylistsQuery, GetStylistsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStylistsQuery, GetStylistsQueryVariables>(GetStylistsDocument, options);
      }
export function useGetStylistsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStylistsQuery, GetStylistsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStylistsQuery, GetStylistsQueryVariables>(GetStylistsDocument, options);
        }
export type GetStylistsQueryHookResult = ReturnType<typeof useGetStylistsQuery>;
export type GetStylistsLazyQueryHookResult = ReturnType<typeof useGetStylistsLazyQuery>;
export type GetStylistsQueryResult = Apollo.QueryResult<GetStylistsQuery, GetStylistsQueryVariables>;
export const GetTermsAndConditionsDocument = gql`
    query getTermsAndConditions {
  termConds {
    data {
      id
      attributes {
        text
      }
    }
  }
}
    `;
export function useGetTermsAndConditionsQuery(baseOptions?: Apollo.QueryHookOptions<GetTermsAndConditionsQuery, GetTermsAndConditionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTermsAndConditionsQuery, GetTermsAndConditionsQueryVariables>(GetTermsAndConditionsDocument, options);
      }
export function useGetTermsAndConditionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTermsAndConditionsQuery, GetTermsAndConditionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTermsAndConditionsQuery, GetTermsAndConditionsQueryVariables>(GetTermsAndConditionsDocument, options);
        }
export type GetTermsAndConditionsQueryHookResult = ReturnType<typeof useGetTermsAndConditionsQuery>;
export type GetTermsAndConditionsLazyQueryHookResult = ReturnType<typeof useGetTermsAndConditionsLazyQuery>;
export type GetTermsAndConditionsQueryResult = Apollo.QueryResult<GetTermsAndConditionsQuery, GetTermsAndConditionsQueryVariables>;
export const GetUnverifiedSalonsDocument = gql`
    query getUnverifiedSalons($input: UnverifiedSalonsInput!) {
  unverifiedSalons(input: $input) {
    id
    name
  }
}
    `;
export function useGetUnverifiedSalonsQuery(baseOptions: Apollo.QueryHookOptions<GetUnverifiedSalonsQuery, GetUnverifiedSalonsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnverifiedSalonsQuery, GetUnverifiedSalonsQueryVariables>(GetUnverifiedSalonsDocument, options);
      }
export function useGetUnverifiedSalonsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnverifiedSalonsQuery, GetUnverifiedSalonsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnverifiedSalonsQuery, GetUnverifiedSalonsQueryVariables>(GetUnverifiedSalonsDocument, options);
        }
export type GetUnverifiedSalonsQueryHookResult = ReturnType<typeof useGetUnverifiedSalonsQuery>;
export type GetUnverifiedSalonsLazyQueryHookResult = ReturnType<typeof useGetUnverifiedSalonsLazyQuery>;
export type GetUnverifiedSalonsQueryResult = Apollo.QueryResult<GetUnverifiedSalonsQuery, GetUnverifiedSalonsQueryVariables>;
export const GetVideoDocument = gql`
    query getVideo($filter: VideoLibraryFiltersInput, $pagination: PaginationArg) {
  videoLibraries(filters: $filter, pagination: $pagination) {
    data {
      id
      attributes {
        link
        ownerName
        Country
        salonName
        BestSA
        BestST
        BestStylist
        year
        videoDescription
      }
    }
    meta {
      pagination {
        total
      }
    }
  }
}
    `;
export function useGetVideoQuery(baseOptions?: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;
export const HomeDocument = gql`
    query home {
  home {
    data {
      attributes {
        hero {
          ...Section
        }
      }
    }
  }
}
    ${SectionFragmentDoc}
${LinkFragmentDoc}
${CardFragmentDoc}
${FileFragmentDoc}`;
export function useHomeQuery(baseOptions?: Apollo.QueryHookOptions<HomeQuery, HomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
      }
export function useHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeQuery, HomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeQuery, HomeQueryVariables>(HomeDocument, options);
        }
export type HomeQueryHookResult = ReturnType<typeof useHomeQuery>;
export type HomeLazyQueryHookResult = ReturnType<typeof useHomeLazyQuery>;
export type HomeQueryResult = Apollo.QueryResult<HomeQuery, HomeQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const RegionDocument = gql`
    query Region($id: ID!) {
  region(id: $id) {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useRegionQuery(baseOptions: Apollo.QueryHookOptions<RegionQuery, RegionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
      }
export function useRegionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionQuery, RegionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionQuery, RegionQueryVariables>(RegionDocument, options);
        }
export type RegionQueryHookResult = ReturnType<typeof useRegionQuery>;
export type RegionLazyQueryHookResult = ReturnType<typeof useRegionLazyQuery>;
export type RegionQueryResult = Apollo.QueryResult<RegionQuery, RegionQueryVariables>;
export const RegionsDocument = gql`
    query Regions {
  regions {
    data {
      id
      attributes {
        name
      }
    }
  }
}
    `;
export function useRegionsQuery(baseOptions?: Apollo.QueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options);
      }
export function useRegionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegionsQuery, RegionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegionsQuery, RegionsQueryVariables>(RegionsDocument, options);
        }
export type RegionsQueryHookResult = ReturnType<typeof useRegionsQuery>;
export type RegionsLazyQueryHookResult = ReturnType<typeof useRegionsLazyQuery>;
export type RegionsQueryResult = Apollo.QueryResult<RegionsQuery, RegionsQueryVariables>;