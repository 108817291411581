import { createContext, FC, PropsWithChildren, useContext, useEffect, useState } from "react"

import { useLoginByPasswordlessMutation, useLogoutMutation, useMeQuery } from "../../graphql"
import { Loader } from "../layout/Loader"

type UserProps = Maybe<MeFragment> | undefined

type ContextProps = { user: UserProps }
const defaultValue: ContextProps = { user: undefined }

type LoginByToken = Maybe<MeFragment> | { message?: string | "token.invalid" }

type DispatchProps = {
  loginByToken: (token: string) => Promise<LoginByToken>
  logout: () => Promise<Maybe<boolean>>
}

type ProviderProps = DispatchProps & ContextProps

const Auth = createContext<ProviderProps>({
  loginByToken: () => Promise.resolve(null),
  logout: () => Promise.resolve(null),
  ...defaultValue,
})

export type AuthProviderProps = PropsWithChildren<Partial<ContextProps>>

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<Maybe<MeFragment>>()
  const [loginMutation] = useLoginByPasswordlessMutation()
  const [logoutMutation] = useLogoutMutation()
  const { loading, data, error, refetch } = useMeQuery()

  useEffect(() => {
    if (!loading) {
      data?.me ? setUser(data?.me as MeFragment) : setUser(null)
    }
  }, [data?.me])

  useEffect(() => {
    if (error) {
      setUser(null)
    }
  }, [error])

  async function loginByToken(loginToken: string): Promise<LoginByToken> {
    return await loginMutation({ variables: { input: { loginToken } } }).then(result => {
      refetch()
        .then(result => result.data.me as MeFragment)
        .then(setUser)
      return result.data?.loginByPasswordless ?? { message: result.errors?.[0].name } ?? null
    })
  }
  async function logout(): Promise<boolean> {
    document.cookie = `jwt=; expires=${new Date(0).toUTCString()}; domain=${
      window.location.hostname
    }; path=/; SameSite=Lax;`
    document.cookie = `jwt.sig=; expires=${new Date(0).toUTCString()}; domain=${
      window.location.hostname
    }; path=/; SameSite=Lax;`

    return await logoutMutation()
      .then(result => {
        result?.data?.logout && setUser(null)
        return true
      })
      .catch(() => false)
  }

  if (loading || user === undefined) return <Loader />

  return <Auth.Provider value={{ loginByToken, logout, user }}>{children}</Auth.Provider>
}

const useAuth: () => ProviderProps = () => useContext(Auth)

export { AuthProvider, useAuth }
