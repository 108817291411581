import { Drawer } from "antd"
import React, { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useGetSalonQuery } from "../../graphql"
import { ReactComponent as Download } from "../../icons/download.svg"
import { ReactComponent as Home } from "../../icons/home.svg"
import { ReactComponent as Info } from "../../icons/info.svg"
import { ReactComponent as Leaderboard } from "../../icons/leaderboard.svg"
import noLogo from "../../icons/logo-avatar.png"
import logo from "../../icons/logo.png"
import { ReactComponent as Logout } from "../../icons/logout.svg"
import { ReactComponent as MenuIcon } from "../../icons/menu_alt.svg"
import { ReactComponent as News } from "../../icons/news.svg"
import { ReactComponent as Play } from "../../icons/play.svg"
import { ReactComponent as Profile } from "../../icons/profile.svg"
import { ReactComponent as Ranking } from "../../icons/ranking.svg"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import "./index.less"

const Index: FC<{ isLogo?: boolean }> = ({ isLogo = true }) => {
  const {
    app: { isPageReady, userTypeId, userType },
  } = useApp()
  const { data: salon } = useGetSalonQuery({ variables: { id: userTypeId!.toString() } })
  const [avatar, setAvatar] = useState<string>(noLogo)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { logout } = useAuth()
  const showDrawer = () => {
    setOpen(true)
  }
  const handleLogout = () => {
    logout().then(() => {
      navigate("/login")
    })
  }
  const onClose = () => {
    setOpen(false)
  }
  useEffect(() => {
    const avatar = salon?.salon?.data?.attributes?.avatar?.data[0]?.attributes?.url
    if (avatar) {
      setAvatar(avatar)
    }
  }, [salon])
  return (
    <div className={"menu"}>
      <button className={"button"} onClick={showDrawer}>
        <div>
          <MenuIcon />
        </div>
      </button>
      <div className={"right-side-block"}>
        <div className={`image-wrapper ${!isPageReady && userType == "salon" && "skeleton"}`}>
          {isLogo && userType == "salon" && <img src={avatar} alt={"essential-beauty"} />}
        </div>
        <div className={"logout-btn"} onClick={handleLogout}>
          <Logout />
        </div>
      </div>
      <Drawer width={"100%"} placement={"right"} onClose={onClose} open={open}>
        <img src={logo} alt={"logo"} className={"logo"} />
        <div className={"drawer"}>
          <div
            className={"item-wrapper"}
            onClick={() => navigate(`/homepage`)}
            style={{ color: pathname.slice(0, 9) == "/homepage" ? "black" : "#677685" }}
          >
            <Home />
            Home
          </div>
          <div
            className={`item-wrapper`}
            onClick={() => {
              navigate("/leaderboard")
            }}
            style={{ color: pathname.slice(0, 12) == "/leaderboard" ? "black" : "#677685" }}
          >
            <Leaderboard />
            Leaderboard
          </div>
          <div
            className={"item-wrapper"}
            onClick={() => navigate("/how-it-works/about")}
            style={{ color: pathname.slice(0, 13) == "/how-it-works" ? "black" : "#677685" }}
          >
            <Info />
            How it works
          </div>
          <div
            className={"item-wrapper"}
            onClick={() => navigate("/library")}
            style={{ color: pathname.slice(0, 8) == "/library" ? "black" : "#677685" }}
          >
            <Play />
            Video library
          </div>
          <div
            className={"item-wrapper"}
            onClick={() => navigate("/news")}
            style={{ color: pathname.slice(0, 5) == "/news" ? "black" : "#677685" }}
          >
            <News />
            Brand updates
          </div>
          {userType == "salon" && (
            <div
              className={`item-wrapper`}
              onClick={() => {
                navigate(`/profile/account`)
              }}
              style={{ color: pathname.slice(0, 8) == "/profile" ? "black" : "#677685" }}
            >
              <Profile />
              Profile
            </div>
          )}
          {userType !== "salon" && (
            <>
              <div
                className={`item-wrapper`}
                onClick={() => {
                  navigate(`/report`)
                }}
                style={{ color: pathname.slice(0, 7) == "/report" ? "black" : "#677685" }}
              >
                <Download />
                Report
              </div>

              <div
                className={`item-wrapper`}
                onClick={() => {
                  navigate(`/dsc-ranking`)
                }}
                style={{ color: pathname.slice(0, 12) == "/dsc-ranking" ? "black" : "#677685" }}
              >
                <Ranking />
                DSC Ranking Report
              </div>
            </>
          )}
        </div>
      </Drawer>
    </div>
  )
}

export default Index
