import React, { FC, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router"
import { useGetSalonQuery } from "../../graphql"
import { ReactComponent as Clock } from "../../icons/clock.svg"
import { ReactComponent as Download } from "../../icons/download.svg"
import { ReactComponent as Home } from "../../icons/home.svg"
import { ReactComponent as Info } from "../../icons/info.svg"
import { ReactComponent as Leaderboard } from "../../icons/leaderboard.svg"
import noLogo from "../../icons/logo-avatar.png"
import logo from "../../icons/logo.png"
import { ReactComponent as Logout } from "../../icons/logout.svg"
import { ReactComponent as News } from "../../icons/news.svg"
import { ReactComponent as Play } from "../../icons/play.svg"
import { ReactComponent as Ranking } from "../../icons/ranking.svg"
import ToolTipWrapper from "../../pages/homepage/components/ToolTipWrapper"
import { useApp } from "../app"
import { useAuth } from "../app/Auth"
import "./index.less"
const Index: FC<{ isLogo?: boolean; isPageReady?: boolean }> = () => {
  const {
    app: { userType, userTypeId, isPageReady, preRegisterData },
  } = useApp()
  const { data: salon } = useGetSalonQuery({ variables: { id: userTypeId!.toString() } })
  const [avatar, setAvatar] = useState<string>(noLogo)
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const handleLogout = () => {
    logout().then(() => {
      navigate("/login")
    })
  }
  useEffect(() => {
    const avatar = salon?.salon?.data?.attributes?.avatar?.data[0]?.attributes?.url
    if (avatar) {
      setAvatar(avatar)
    }
  }, [salon])
  if (user && !preRegisterData && pathname !== "/terms") {
    return (
      <div className={"desktop-navbar"} style={{ borderRight: "1px solid black" }}>
        <div className={`logo-wrapper`}>
          <img src={logo} alt={"logo"} style={{ width: "100%", height: "100%" }} />
        </div>
        <nav className={"navbar"}>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Homepage"}>
              <Home
                onClick={() => {
                  navigate(`/homepage`)
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 9) == "/homepage" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Leaderboard"}>
              <Leaderboard
                onClick={() => {
                  navigate("/leaderboard")
                }}
                className={"nav-icon"}
                style={{
                  opacity: pathname.slice(0, 12) == "/leaderboard" ? "1" : "0.6",
                }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"How it works"}>
              <Info
                onClick={() => {
                  navigate("/how-it-works/about")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 13) == "/how-it-works" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Video library"}>
              <Play
                onClick={() => {
                  navigate("/library")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 8) == "/library" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>
          <div>
            <ToolTipWrapper type={"navbar"} linkName={"Brand updates"}>
              <News
                onClick={() => {
                  navigate("/news")
                }}
                className={"nav-icon"}
                style={{ opacity: pathname.slice(0, 5) == "/news" ? "1" : "0.6" }}
              />
            </ToolTipWrapper>
          </div>

          {userType !== "salon" && (
            <div className={`${!isPageReady && userType == "salon" && "skeleton"}`}>
              <ToolTipWrapper type={"navbar"} linkName={"Report"}>
                <Download
                  onClick={() => {
                    if (isPageReady || userType !== "salon") {
                      navigate("/report")
                    }
                  }}
                  className={"nav-icon"}
                  style={{
                    opacity: pathname.slice(0, 7) == "/report" ? "1" : "0.6",
                  }}
                />
              </ToolTipWrapper>
            </div>
          )}
          {userType !== "salon" && (
            <div>
              <ToolTipWrapper type={"navbar"} linkName={"DSC ranking report"}>
                <Ranking
                  onClick={() => {
                    navigate("/dsc-ranking")
                  }}
                  className={"nav-icon"}
                  style={{ opacity: pathname.slice(0, 12) == "/dsc-ranking" ? "1" : "0.6" }}
                />
              </ToolTipWrapper>
            </div>
          )}
          {userType !== "salon" && (
            <div>
              <ToolTipWrapper type={"navbar"} linkName={"Activity log"}>
                <Clock
                  onClick={() => {
                    navigate("/activity")
                  }}
                  className={"nav-icon"}
                  style={{
                    opacity:
                      !isPageReady && userType == "salon" ? "0" : pathname.slice(0, 9) == "/activity" ? "1" : "0.6",
                  }}
                />
              </ToolTipWrapper>
            </div>
          )}
        </nav>
        {userType == "salon" ? (
          <div
            className={`image-wrapper`}
            onClick={() => {
              navigate(`/profile/account`)
            }}
          >
            <ToolTipWrapper type={"navbar"} linkName={"Profile"}>
              <img src={avatar} alt={"avatar"} style={{ width: "100%", height: "100%" }} />
            </ToolTipWrapper>
          </div>
        ) : (
          <div />
        )}
        <div className={"logout-btn"} onClick={handleLogout}>
          <ToolTipWrapper type={"navbar"} linkName={"Logout"}>
            <Logout />
          </ToolTipWrapper>
        </div>
      </div>
    )
  } else {
    return null
  }
}
export default Index
